import {
  addMonths,
  isAfter,
  isBefore,
  startOfToday,
} from 'date-fns';

export function isCurrentlyRenewable(license) {
  return license.renewable
    && license.costs !== null
    && ((isBefore(license.valid.end, addMonths(new Date(), 2))
        && isAfter(license.valid.end, addMonths(new Date(), -3)))
      || isAfter(startOfToday(), license.valid.end));
}

export const latestLicensePerLicenseType = (licenses) => licenses.filter(
  (l) => !licenses.find(
    (ll) => ll.sport === l.sport
        && ll.licenseType === l.licenseType
        && isAfter(ll.valid.end, l.valid.end),
  ),
);
